.icon{
    fill:none;
    stroke-width: 0;
    font-size: 24px;
}
.icon > path:nth-child(2){
    stroke-width: 1.25px;
}
.icon > path:nth-child(1){
    stroke-width: 0 !important;
}