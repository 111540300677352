.form-enter{
    opacity: 0;
    transform: scaleY(0.5);
}
.form-enter-active{
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.form-exit{
    opacity: 1;
}
.form-exit-active{
    display: none;
    opacity: 0;
    transform: scaleY(1);
    transition: opacity 300ms, transform 300ms;
}