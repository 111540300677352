@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "lato";
    src: url("fonts/Lato-Bold.ttf") format("truetype");
}
@font-face {
    font-family: "montserrat";
    src: url("fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
}

h1, h2, h3, h4{
    font-family: "lato";
}
body,p{
    font-family: "montserrat" !important;
}

@keyframes spinner-fade{
    from{opacity: 1}
    to{
      opacity: 0.01;
    }
  }

  .socialShareBackdropFadeIn{
    justify-content: center !important;
  }
  .socialShareBackdropFadeOut{
    justify-content: center !important;
  }