* {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */  
}
*::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent;
}  
*::-webkit-scrollbar {
  width: 3px !important;
  background-color: transparent;
}  
*::-webkit-scrollbar-thumb {
  background-color: transparent;
}