.btn-relative{
    position: relative;
    /* background-color: white; */
}

.btn-relative.active{
    /* background-color: #ebebeb; */
}

.btn-relative::before{
    content: "";
    position: absolute;
    inset: 0;
    background-color: currentColor;
    z-index: -1;
    border-radius: 0.5rem;

}
.active::before{
    transform: translateY(3px);
    transition: transform 250ms;
}
